<template>
  <a-modal v-model="modalVisible" title="运行历史" width="75%" :footer="null" :centered="true" @cancel="cancelModal" :bodyStyle="bodyStyle">
    <a-table :columns="tableColumns" :data-source="tableData" row-key="abnoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="showRunDataSonDetail(value,record)">详情</a-button>
          </span>
      <span slot="abnotime" slot-scope="value, record">
            <span>{{moment(value,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")}}</span>
          </span>
      <span slot="abnocode" slot-scope="value, record">
            <span>{{abnoType[value]}}</span>
          </span>
    </a-table>
    <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(totals, range) => `共${totals}条`" style="margin-top: 20px; text-align: right;">
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}条/页</span>
      </template>
    </a-pagination>
    <a-modal v-model="runDataSonVisible">
      <template slot="footer">
        <a-button @click="runDataSonVisible=false">关闭</a-button>
      </template>
      <h3 style="font-size: 22px">终端自检异常运行数据</h3>
      <a-form-model ref="run_modalForm" :model="runDatas" :label-col="{span:10}" :wrapper-col="{span:14}">
        <a-form-model-item label="异常设备编码" prop="devicenum">
          <a-input v-model="runDatas.devicenum" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="异常类型" prop="abnocode">
          <a-input v-model="abnoType[runDatas.abnocode]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="异常上报时间" prop="abnotime">
          <a-input v-model="moment(runDatas.abnotime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='6'" label="设备重启时间" prop="_state_reboot_time">
          <a-input v-model="runDatas._state_reboot_time" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="实际运行高度和楼层高度的差值" prop="_hei_difference">
          <a-input v-model="runDatas._hei_difference" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="起始楼层" prop="_begin_floor">
          <a-input v-model="runDatas._begin_floor" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='1'" label="终止楼层" prop="_end_floor">
          <a-input v-model="runDatas._end_floor" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="起始楼层的气压" prop="_begin_floor_pressure">
          <a-input v-model="Number(runDatas._begin_floor_pressure).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="终止楼层的气压" prop="_end_floor_pressure">
          <a-input v-model="Number(runDatas._end_floor_pressure).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="楼层高度队列常数的平均值" prop="_floor_hei_mean">
          <a-input v-model="Number(runDatas._floor_hei_mean).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行时间" prop="_run_time">
          <a-input v-model="Number(runDatas._run_time).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行平均速度" prop="_run_mean_speed">
          <a-input v-model="Number(runDatas._run_mean_speed).toFixed(2)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='4'" label="录像失败开始时间" prop="_video_fail_begin_time">
          <a-input v-model="moment(runDatas._video_fail_begin_time,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item v-if="runDatas.abnocode=='5'" label="录像失败结束时间" prop="_video_fail_end_time">
          <a-input v-model="moment(runDatas._video_fail_end_time,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行时间" prop="accumulative_run_time">
          <a-input v-model="Number(runDatas.accumulative_run_time/60).toFixed(2)+'小时'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行时间" prop="today_run_time">
          <a-input v-model="Number(runDatas.today_run_time).toFixed(2)+'分钟'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行次数" prop="accumulative_run_num">
          <a-input v-model="Number(runDatas.accumulative_run_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行次数" prop="today_run_num">
          <a-input v-model="Number(runDatas.today_run_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计运行里程" prop="cumulative_mileage">
          <a-input v-model="Number(runDatas.cumulative_mileage).toFixed(2)+'公里'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日运行里程" prop="today_mileage">
          <a-input v-model="Number(runDatas.today_mileage).toFixed(2)+'公里'" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计开关门次数" prop="accumulative_door_num">
          <a-input v-model="Number(runDatas.accumulative_door_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日开关门次数" prop="today_door_num">
          <a-input v-model="Number(runDatas.today_door_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="累计人流量" prop="accumulative_passenger_num">
          <a-input v-model="Number(runDatas.accumulative_passenger_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当日人流量" prop="today_passenger_num">
          <a-input v-model="Number(runDatas.today_passenger_num).toFixed(0)" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前状态" prop="car_status">
          <a-input v-model="carStatusMap[runDatas.car_status]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="运行方向" prop="car_direction">
          <a-input v-model="carDirectionMap[runDatas.car_direction]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="开锁区域" prop="door_zone">
          <a-input v-model="doorZoneMap[runDatas.door_zone]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前楼层" prop="car_position">
          <a-input v-model="runDatas.car_position" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="关门到位" prop="door_status">
          <a-input v-model="doorStatusMap[runDatas.door_status]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="服务模式" prop="service_mode">
          <a-input v-model="serviceModeMap[runDatas.service_mode]" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
        <a-form-model-item label="当前乘客数" prop="passenger_num">
          <a-input v-model="runDatas.passenger_num" read-only :style="{'background-color':'white','font-size':'20px'}"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-modal>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {findDevAbnormalListByCondition} from "A/ai";
export default{
  name: 'traceBack',
  mixins: [pagination],
  props: {
    Abnotime:{
      default: false
    },
    visible: {
      default: false
    },
  },
  data(){
    return {
      moment,
      modalVisible:false,
      runDataSonVisible:false,
      bodyStyle:{
        "padding":"10px"
      },
      abnoType:['','高度差异常','安全回路插片损坏','检修插片损坏','录像失败开始','录像失败结束'],
      runDatas:{
        abnocode:'',
        devicenum:'',
        abnotime:'',
        _hei_difference:'',
        _begin_floor:'',
        _end_floor:'',
        _begin_floor_pressure:'',
        _end_floor_pressure:'',
        _floor_hei_mean:'',
        _run_time:'',
        _run_mean_speed:'',
        _video_fail_begin_time:'',
        _video_fail_end_time:'',
        accumulative_run_time:'',
        today_run_time:'',
        accumulative_run_num:'',
        today_run_num:'',
        cumulative_mileage:'',
        today_mileage:'',
        accumulative_door_num:'',
        today_door_num:'',
        accumulative_passenger_num:'',
        today_passenger_num:'',
        weather:'',
        temperature:'',
        car_status:'',
        car_direction:'',
        door_zone:'',
        car_position:'',
        door_status:'',
        service_mode:'',
        passenger_num:'',
        _state_reboot_time:'',
      },
      tableColumns:[
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '异常事件类型',
          dataIndex: 'abnocode',
          key: 'abnocode',
          ellipsis: true,
          scopedSlots: { customRender: 'abnocode' }
        },
        {
          title: '楼层高度差',
          dataIndex: '_hei_difference',
          key: '_hei_difference',
          ellipsis: true,
        },
        {
          title: '异常上报时间',
          dataIndex: 'abnotime',
          key: 'abnotime',
          ellipsis: true,
          scopedSlots: { customRender: 'abnotime' }
        },
        {
          title: '终端自检数据',
          key: 'operation',
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableLoading: false,
      tableData:[],
      devicenum:'',
      carStatusMap: {
        '00': '停止',
        '01': '运行',
      },
      carDirectionMap: {
        '00': '无方向',
        '01': '上行',
        '02': '下行',
      },
      doorZoneMap: {
        '00': '轿厢在非开锁区域',
        '01': '轿厢在开锁区域',
      },
      doorStatusMap: {
        // '00': '未关门到位',
        '00': '开门',
        '01': '关门到位',
      },
      serviceModeMap: {
        '0': '停止服务',
        '1': '正常运行',
        '2': '检修',
        '3': '消防返回',
        '4': '消防员运行',
        '5': '应急电源运行',
        '6': '地震模式',
        '7': '未知',
      },
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  methods:{
    cancelModal(e){
      this.modalVisible = false
    },
    init() {
      this.getTableData();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params={
        devicenum:"",
        abnotime:moment(this.Abnotime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      }
      findDevAbnormalListByCondition(params).then(res=>{
        if(res&&res.returncode=='0') {
          this.tableData = res.item
          this.pagination.total = res.count
        }
      })
    },
    showRunDataSonDetail(value,record){
      let params={
        abnoid:record.abnoid,
        // devicenum:record.devicenum,
        // abnotime:moment(record.abnotime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"),
        // abnocode: record.abnocode,
      }
      findDevAbnormalListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          if(!(res.item[0] == undefined || res.item[0].length <= 0)) {
            this.runDatas = res.item[0]
            this.runDataSonVisible = true
          }else{
            this.$message.info("未找到相应日期的自检异常运行数据")
          }
        }
      })
    },
  }
}
</script>